:root {
  --amplify-font-family: inherit;
  --amplify-primary-color: #c8102e;
  --amplify-primary-tint: #c8102e;
  --amplify-primary-shade: #c8102e;
  --amplify-secondary-tint: #c8102e;
}

#header h1 {
  font-size: 1.1em;
  flex-grow: 1;
}

#main {
  margin-top: 1.5em;
}

.logo-tims-rewards {
  background: url("/tims-rewards.svg") 0 1px no-repeat;
  color: transparent;
}

[lang="fr"] .logo-tims-rewards {
  background-image: url("/tims-rewards-fr.svg");
}

.form-actions {
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
}

.loader {
  position: absolute;
  left: 50%;
  margin: 30px 0 0 -20px;
}

.icon-expand svg {
  transition: transform 300ms;
}

.icon-expand.expanded svg {
  transform: scaleY(-1);
}

#container-summary2 td {
  width: 5.5em;
}

.container-error {
  max-width: 50em;
  margin: 1em auto;
  padding: 2em;
}

.container-table th {
  background: #f3f3f3;
  line-height: 1.1em;
  padding: 0.6em 0.6em;
}

.container-table td {
  white-space: nowrap;
  padding: 0.4em 0.6em;
}

.container-table tfoot {
  background: #f3f3f3;
}

.container-table {
  max-width: 50em;
  padding: 1em;
  margin: 1em auto;
}

.container-table table {
  margin-top: 0.5rem;
}

.container-table .gross-margin {
  margin-top: 2rem;
}

.container-table tfoot .MuiTableCell-footer {
  color: inherit;
  font-size: 0.875rem;
}

.container-table h3,
.container-table h4 {
  font-weight: normal;
  margin: 0 0.3em 0 0;
  line-height: 1.9rem;
  display: inline-block;
}

.right-border {
  border-right: 1px solid #ddd;
}

h3 + .button-expand {
  margin: -1em 0 -0.8em;
}

.selectors {
  margin: 0 auto;
  max-width: 28em;
  display: flex;
  justify-content: space-between;
}

.selectors .MuiFormControl-root {
  margin-right: 1em;
}

#store-select {
  width: 6em;
}

.selector-week {
  width: 15em;
}

.buttons-navigate svg {
  fill: #000;
  font-size: 1.7em;
  width: 1em;
  height: 1em;
}

.buttons-navigate :disabled svg {
  fill: #ccc;
}

svg.icon-tooltip {
  color: #aaa;
  font-size: 1.1em;
}

thead svg.icon-tooltip {
  float: right;
  margin-left: 0.3em;
}

tbody svg.icon-tooltip,
tfoot svg.icon-tooltip {
  float: left;
  margin-right: 0.4em;
}

h4 svg.icon-tooltip {
  margin: 0 0 -2px 0.3em;
}

.recharts-responsive-container {
  margin: 0.5em auto;
  font-size: 0.8em;
}

.recharts-bar-rectangle {
  opacity: 0.75;
}

.recharts-legend-item {
  margin-right: 2em !important;
}
